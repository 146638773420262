import React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import {v4 as uuidv4} from "uuid"
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router';


export default function CreateListing() {
    const navigate = useNavigate()
    const auth = getAuth()
    const [geolocationEnabled, setGeolocationEnabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        type: "rent",
        name: "",
        bedrooms: 1,
        bathrooms: 1,
        parking: false,
        furnished: false,
        address: "",
        description: "",
        offer: true,
        regularPrice: 0,
        discountedPrice: 0,
        latitude: 0,
        longitude: 0,
        images: {}
    })
    const {type, name, bedrooms, bathrooms, parking, furnished, address, description, offer, regularPrice, discountedPrice, latitude, longitude, images} = formData;

    function onChange(e){
        let boolean = null;
        if(e.target.value === "true"){
            boolean=true
        }
        if(e.target.value === "false"){
            boolean=false;
        }
        // files
        if(e.target.files){
            setFormData((prevState)=>({
                ...prevState,
                images: e.target.files
            }))
        }
        // text/boolean/number
        if(!e.target.files){
            setFormData((prevState)=> ({
                ...prevState,
                [e.target.id]: boolean ?? e.target.value,
            }))
        }

    }
    async function onSubmit(e){
        e.preventDefault()
        setLoading(true);
        if(+discountedPrice >= +regularPrice){
            setLoading(false)
            toast.error("los precios en descuento deben ser menores que el original")
            return;
        }
        if(images.length > 6){
            setLoading(false)
            toast.error ("Maximum allowed images are 6")
            return;
        }
        // for google geolocation 
        let geolocation = {}
        let location
        if(geolocationEnabled){
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAN1D7dmIb1PoK5DlnMfUeLQY9JsCWB728`);
            const data = await response.json();
            console.log(data);
            geolocation.lat = data.results[0]?.geometry.location.lat ?? 0; 
            geolocation.lng = data.results[0]?.geometry.location.lng ?? 0;

            location = data.status === "Sin resultados" && undefined;

            if(location === undefined){
                setLoading(false)
                toast.error("Porfavor adjunta una direccion cercana de la casa")
                return;
            }
        } else {
            geolocation.lat = latitude;
            geolocation.lng = longitude;
        }

        async function storeImage(image){
            return new Promise((resolve, reject)=>{
                const storage = getStorage();
                const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
                const storageRef = ref(storage, filename);
                const uploadTask = uploadBytesResumable(storageRef, image);

                uploadTask.on('state_changed', 
                        (snapshot) => {
                            // Observe state change events such as progress, pause, and resume
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                            case 'paused':
                                console.log('La subida de la propiedad ah sido pausada');
                                break;
                            case 'running':
                                console.log('Subida de la propiedad continuada');
                                break;
                            }
                        }, 
                        (error) => {
                            // Handle unsuccessful uploads
                            reject(error)
                        }, 
                        () => {
                            // Handle successful uploads on complete
                            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL);
                            });
                        }
                        );
            })

        }

        const imgUrls = await Promise.all(
            [...images].map((image) => storeImage(image))).catch((error)=>{
                console.error(error)
                setLoading(false)
                toast.error("Imagenes no subidas")
                return;
            })
        // console.log(imgUrls)

        const formDataCopy = {
            ...formData,
            imgUrls,
            geolocation,
            timestamp: serverTimestamp(),
            userRef: auth.currentUser.uid
        };
        delete formDataCopy.images;
        !formDataCopy.offer && delete formDataCopy.discountedPrice;
        delete formDataCopy.latitude
        delete formDataCopy.longitude
        // submit
        const docRef = await addDoc(collection(db, "listings"), formDataCopy)
        setLoading(false)
        toast.success("Listing created")
        navigate(`/category/${formDataCopy.type}/${docRef.id}`)
    }


    if(loading){
        return <Spinner/>
    }
  return (
    <main className='max-w-md px-2 mx-auto'>
        <h1 className='text-3xl text-center mt-6 font-bold'>Crea una venta</h1>
        <form onSubmit={onSubmit}>
            <p className='text-lg mt-6 font-semibold'>Venta / Arriendo</p>
            <div className='flex'>
                <button 
                type='button'
                id="type"
                value="sale" 
                onClick={onChange} 
                className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${type === "rent"? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    Venta
                </button>
                <button 
                type='button'
                id="type"
                value="rent" 
                onClick={onChange} 
                className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${type === "sale"? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    Arriendo
                </button>
            </div>
            <p className='text-lg mt-6 font-semibold'>Nombre</p>
            <input 
            type="text" 
            id="name" 
            value={name} 
            onChange={onChange} 
            placeholder="Property Name" 
            maxLength="32" 
            minLength="10" 
            required 
            className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-500 focus:bg-white focus:border-slate-600 mb-6'/>
            <div className='flex space-x-6 mb-6'>
                <div>
                    <p className='text-lg font-semibold'>Cuartos</p> 
                    <input 
                    type="number" 
                    id="bedrooms" 
                    value={bedrooms} 
                    onChange={onChange} 
                    min="1" 
                    max="50" 
                    required 
                    className='w-full px-4 py-2 text-xl test-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center' />
                </div>
                <div>
                    <p className='text-lg font-semibold'>Baños</p> 
                    <input 
                    type="number" 
                    id="bathrooms" 
                    value={bathrooms} 
                    onChange={onChange} 
                    min="1" 
                    max="50" 
                    required 
                    className='w-full px-4 py-2 text-xl test-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center' />
                </div>
            </div>
            <p className='text-lg mt-6 font-semibold'>Parqueadero propio?</p>
            <div className='flex'>
                <button 
                type='button'
                id="parking"
                value={true} 
                onClick={onChange} 
                className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${!parking ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    si
                </button>
                <button 
                type='button'
                id="parking"
                value={false} 
                onClick={onChange} 
                className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${parking ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    No
                </button>
            </div>
            <p className='text-lg mt-6 font-semibold'>Amueblado</p>
            <div className='flex'>
                <button 
                type='button'
                id="furnished"
                value={true} 
                onClick={onChange} 
                className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${!furnished ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    Si
                </button>
                <button 
                type='button'
                id="furnished"
                value={false} 
                onClick={onChange} 
                className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${furnished ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    No
                </button>
            </div>
            <p className='text-lg mt-6 font-semibold'>Address</p>
            <textarea 
            type="text" 
            id="address" 
            value={address} 
            onChange={onChange} 
            placeholder="Address"  
            required 
            className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-500 focus:bg-white focus:border-slate-600 mb-6'/>
            {!geolocationEnabled && (
                <div className='flex space-x-6 justify-start mb-6'>
                    <div>
                        <p className='text-lg font-semibold'>Latitud</p>
                        <input 
                        type="number" 
                        id="latitude" 
                        value={latitude}
                        onChange={onChange}
                        required 
                        min="-90"
                        max="90"                         className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:text-gray-700 focus:border-slate-600 text-center' />
                    </div>
                    <div>
                        <p className='text-lg font-semibold'>Longitud</p>
                        <input 
                        type="number" 
                        id="longitude" 
                        value={longitude} 
                        onChange={onChange} 
                        required 
                        min="-180"
                        max="180" 
                        className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:text-gray-700 focus:border-slate-600 text-center' />
                    </div>
                </div>
            )}
            <p className='text-lg font-semibold'>Descripcion de la propiedad</p>
            <textarea 
            type="text" 
            id="description" 
            value={description} 
            onChange={onChange} 
            placeholder="Description"  
            required 
            className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-500 focus:bg-white focus:border-slate-600 mb-6'/>
            <p className='text-lg font-semibold'>En oferta?</p>
            <div className='flex mb-6'>
                <button 
                type='button'
                id="offer"
                value={true} 
                onClick={onChange} 
                className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${!offer ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    Si
                </button>
                <button 
                type='button'
                id="offer"
                value={false} 
                onClick={onChange} 
                className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${offer ? "bg-white text-black": "bg-slate-600 text-white"}`}>
                    No
                </button>
            </div>
            <div className='flex items-center mb-6'>
                <div >
                <p className='text-lg font-semibold'>Precio normal</p>
                    <div className='flex w-full justify-center items-center space-x-6'>
                    <input 
                    type="number" 
                    id="regularPrice" 
                    value={regularPrice}
                     onChange={onChange} 
                     min="50" 
                     max="400000000" 
                     required 
                     className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center' />
                    {type === "rent" && (
                        <div className=''>
                            <p className='text-md w-full whitespace-nowrap'>Pesos/mes </p>
                        </div>
                    )}
                    </div>
                    
                </div>
            </div>
            {offer && (
                            <div className='flex items-center mb-6'>
                            <div >
                            <p className='text-lg font-semibold'>precio con el descuento</p>
                                <div className='flex w-full justify-center items-center space-x-6'>
                                <input 
                                type="number"
                                id="discountedPrice" 
                                value={discountedPrice} 
                                onChange={onChange} 
                                min="50" 
                                max="400000000" 
                                required={offer}
                                className='w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center' />
                                {type === "rent" && (
                                    <div className=''>
                                        <p className='text-md w-full whitespace-nowrap'>$ / mes</p>
                                    </div>
                                )}
                                </div>
                                
                            </div>
                        </div>
            )}
            <div className='mb-6'>
                <p className='text-lg font-semibold'>Imagenes</p>
                <p className='text-gray-600 text-sm'>La primera imagen sera la portada(max 6)</p>
                <input 
                type="file" 
                id="images" 
                onChange={onChange} 
                accept=".jpg, .png, .jpeg" 
                multiple 
                required
                className='w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600' />
            </div>
            <button type='submit' className='mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg active:bg-blue-900 active:shadow-lg transition duration-150 ease-in-out'>
                Publica la propiedad
            </button>
        </form>
    </main>
  )
}
