/*// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBcYWRhjWu__EoCKd4j97fJ1cSadg16HV0",
  authDomain: "realtor-clone-react-8874b.firebaseapp.com",
  projectId: "realtor-clone-react-8874b",
  storageBucket: "realtor-clone-react-8874b.appspot.com",
  messagingSenderId: "674837230156",
  appId: "1:674837230156:web:71048ef114d599e5b67ae9"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore()
*/
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCicZmxF_eVhD3Zx9MtRIXsJPvrwZMCqkc",
  authDomain: "skillful-rain-393100.firebaseapp.com",
  databaseURL: "https://skillful-rain-393100-default-rtdb.firebaseio.com",
  projectId: "skillful-rain-393100",
  storageBucket: "skillful-rain-393100.appspot.com",
  messagingSenderId: "1084280730103",
  appId: "1:1084280730103:web:e38499c2b8d3b6f8c6fef2",
  measurementId: "G-W7NFL6B3VM"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore()
